<template>
    <main class="h-100 w-100">
      <!-- <h4 class="mt-2">This is a temporary page to manage all viewpoints.</h4> -->
      <div>Total frames generated: {{ framesGenerated }} </div>
      <div>Total frames annotated: {{ framesAnnotated }} </div>

      <table class="table table-fixed table-striped table-sm">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Tags</th>
            <!-- <th scope="col">Source</th> -->
            <th scope="col">Frames</th>
            <!-- <th scope="col">Annontated</th> -->
            <!-- <th scope="col">AthleteID</th> -->
            <th scope="col">KAS</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="resource in sortedResources" :key="resource.id">
            <td style="font-size:0.7em"><div style="cursor: pointer;" @click.stop="$router.push({ path: `/viewpoint/${resource.id}`})">{{ resource.id}}</div></td>
            <td>{{ resource.name}}</td>
            <td>
                <div v-for="tag in resource.tags" :key="tag" class="badge bg-secondary my-auto mx-1" style="height: 20px;">{{tag}}</div>
            </td>
            <!-- <td>{{ resource.source}}</td> -->
            <td style="font-size: 0.7em">{{ resource.framesGenerated ? resource.annotatedFrames : 'N/a' }} / {{ resource.framesGenerated ? resource.totalFrames : 'N/a' }}</td>
            <!-- <td>{{ resource.framesGenerated ? resource.annotatedFrames : 'N/a' }}</td> -->
            <!-- <td>{{ resource.athleteId }}</td> -->
            <td>{{ resource.kickAnnotationState ? resource.kickAnnotationState : 'INCOMPLETE' }}</td>
            <td>
              <!-- <div @click.stop="deleteViewpoint(resource.id)" class="btn btn-danger btn-xs" size="sm" variant="danger">Delete</div> -->
              <!-- <div @click.stop="selectedResourceId = resource.id; editModal.show()" class="ms-1 btn btn-primary btn-xs" size="sm" variant="info">Quick Edit</div> -->
              <div @click.stop="selectedResourceId = resource.id; annotateModal.show()" class="ms-1 btn btn-success btn-xs" size="sm" variant="success">Annotate</div>
            </td>
          </tr>
        </tbody>
      </table>
      <infinite-loading @infinite="infiniteHandler"></infinite-loading>


      
      <!-- Edit viewpoint modal -->
      <div class="modal fade" ref="editModal" tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ selectedResource ? selectedResource.name || selectedResource.id : '' }}</h5>
              <button type="button" class="btn-close" aria-label="Close" @click="editModal.hide()"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="form-group col">
                  <label for="inputEmail4">Athletes:</label>
                  <select-athlete v-if="selectedResource" @input="selectedResource.athleteId = $event.id" />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="editModal.hide()">Close</button>
              <button type="button" class="btn btn-success" @click="save" :disabled="saving">Save <img v-if="saving" class="loader" src="@/assets/loader.gif"/></button>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Annotate select modal -->
      <div class="modal fade" ref="annotateModal" tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Choose annotation type</h5>
              <button type="button" class="btn-close" aria-label="Close" @click="annotateModal.hide()"></button>
            </div>
            <div class="modal-body">
              <div class="d-grid">
                <button type="button" class="btn btn-secondary my-2" @click="annotateModal.hide();$router.push({ path: `/viewpoint/${selectedResource.id}/annotate` })">Event Classification</button>
                <button type="button" class="btn btn-success my-2" @click="annotateModal.hide();$router.push({ path: `/viewpoint/${selectedResource.id}/annotate-kicks` })">Kick Localization</button>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="annotateModal.hide()">Close</button>
            </div>
          </div>
        </div>
      </div>
    </main>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import { getViewpoints, upsertViewpoint } from "@/api";
import SelectAthlete from "@/components/SelectAthlete";

export default {
  mixins: {  },
  components: {
    SelectAthlete
  },
  data() {
      return {
        errorLoading: null,
        isEditingName: false,
        editModal: null,
        annotateModal: null,
        resources: [],
        selectedResourceId: null,
        saving: false,
        page: 0,
      }
  },
  async mounted() {
    console.log("Viewpoints mounted");
    this.editModal = new bootstrap.Modal(this.$refs.editModal);
    this.annotateModal = new bootstrap.Modal(this.$refs.annotateModal);
  },
  async destroyed() {
    console.log("Viewpoints destroyed");
  },
  computed: {
    selectedResource() { return this.resources.find(x => x.id == this.selectedResourceId) },
    sortedResources() {
      return this.resources.sort(function(a, b) {
        if (!b.framesGenerated) return -1; // Sort a before b
        if (!a.framesGenerated) return +1; // Sort b beofor
        let annotatedRatioA = a.annotatedFrames / a.totalFrames;
        let annotatedRatioB = b.annotatedFrames / b.totalFrames;
        if (annotatedRatioA == annotatedRatioB) return 0;
        return annotatedRatioA > annotatedRatioB; // Sort a before b if it has less frames annotated
      });
    },
    framesGenerated() {
      return this.resources.reduce(function(acc, viewpoint) {
        if (!viewpoint || isNaN(viewpoint.totalFrames)) return acc;
        return acc + viewpoint.totalFrames;
      }, 0);
    },
    framesAnnotated() {
      return this.resources.reduce(function(acc, viewpoint) {
        if (!viewpoint || isNaN(viewpoint.annotatedFrames)) return acc;
        return acc + viewpoint.annotatedFrames;
      }, 0);
    }
  },
  watch: {
  },
  methods: {
    async save() {
      this.saving = true;
      await upsertViewpoint(this.selectedResource.id);
      this.saving = false;
    },
    async infiniteHandler($state) {
      let { data } = await getViewpoints({
        page: this.page,
        loadAnnotatedFrames: true,
      })

      if (data.viewpoints.length) {
        this.page += 1;
        this.resources.push(...data.viewpoints);
        $state.loaded();
      } else {
        $state.complete();
      }
    },
  }
}
</script>

<style>

</style>